import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  add: <Trans i18nKey="button.add" defaults="Add" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  copy: <Trans i18nKey="button.copy" defaults="Copy" />,
  createNotification: (
    <Trans i18nKey="button.createNotification" defaults="Create notification" />
  ),
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  help: <Trans i18nKey="button.help" defaults="Help" />,
  placeholderList: (
    <Trans i18nKey="button.placeholderList" defaults="Placeholder list" />
  ),
  resetFields: <Trans i18nKey="button.resetFields" defaults="Reset fields" />,
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  validate: <Trans i18nKey="button.validate" defaults="Validate" />,
});
