import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  ConfirmDeleteModal,
  ExternalLink,
  Icon,
  Loadable,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  tableRowClickEventWrapper,
  useIndeterminateRowSelectCheckbox,
  useModal,
} from '@fleet/shared';
import { NotificationTemplateForTable } from 'dto/notificationTemplate';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import {
  notificationTemplatesFilterSelector,
  notificationTemplatesSelector,
} from 'features/notificationTemplate/notificationTemplateSelectors';
import { TransTableHead } from 'i18n/trans/tableHead';
import {
  deleteNotificationTemplates,
  getNotificationTemplates,
} from 'features/notificationTemplate/notificationTemplateActions';
import { notificationTemplatesTableLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import _isEmpty from 'lodash/isEmpty';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { TransModal } from 'i18n/trans/modal';
import { useHistory } from 'react-router-dom';
import { dtid } from 'helpers/data-test-ids';

interface NotificationTemplatesTableProps {}

export const NotificationTemplatesTable: FC<NotificationTemplatesTableProps> =
  () => {
    const notificationTemplates = useSelector(notificationTemplatesSelector);
    const loading = useSelector(notificationTemplatesTableLoadingSelector);
    const notificationTemplatesFilter = useSelector(
      notificationTemplatesFilterSelector
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();

    const getPage = useCallback(
      (pageSize: number) => {
        if (notificationTemplates) {
          const { limit = pageSize, offset } = notificationTemplates;
          return offset / limit;
        }
        return 0;
      },
      [notificationTemplates]
    );
    const data = useMemo(
      () => notificationTemplates?.items ?? [],
      [notificationTemplates]
    );

    const link = useCallback(
      (row: Row<NotificationTemplateForTable>) =>
        `/notification-templates/edit/${row.original.id}`,
      []
    );

    const columns = useMemo<TableColumns<NotificationTemplateForTable>>(
      () => [
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="name" />,
          Cell: ({ row, value }) => (
            <Link to={link(row)} onClick={tableRowClickEventWrapper}>
              {value}
            </Link>
          ),
        },
        {
          id: 'organization.id',
          accessor: ({ organization }) => organization.name,
          Header: <TransTableHead i18nKey="owner" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) => (
            <ExternalLink
              content={row.original.organization.name}
              underline="none"
              path={`/Contacts/Organization/Edit/${row.original.organization.id}`}
            />
          ),
        },
        {
          id: 'type.id',
          accessor: ({ type }) => type.name,
          Header: <TransTableHead i18nKey="type" />,
        },
        {
          id: 'languages.id',
          accessor: ({ languages }) =>
            languages.map((lang) => lang.name).join(', '),
          Header: <TransTableHead i18nKey="language" />,
        },
        {
          accessor: 'hasEmailContent',
          Header: <TransTableHead i18nKey="email" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) =>
            row.original.hasEmailContent && (
              <Icon name="check" color="success" />
            ),
        },
        {
          accessor: 'hasSmsContent',
          Header: <TransTableHead i18nKey="sms" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) =>
            row.original.hasSmsContent && <Icon name="check" color="success" />,
        },
        {
          accessor: 'hasPushContent',
          Header: <TransTableHead i18nKey="pushNotification" />,
          Cell: ({ row }: { row: Row<NotificationTemplateForTable> }) =>
            row.original.hasPushContent && (
              <Icon name="check" color="success" />
            ),
        },
      ],
      [link]
    );

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        await dispatch(
          getNotificationTemplates({
            ...notificationTemplatesFilter,
            ...paginationParams,
          })
        ),
      [dispatch, notificationTemplatesFilter]
    );

    const table = useTable(
      {
        data,
        columns,
        pageCount: -1,
        useControlledState: (state) => ({
          ...state,
          pageIndex: getPage(state.pageSize),
        }),
        manualPagination: true,
        onPageChange: handlePageChange,
        total: notificationTemplates?.totalCount,
      },
      usePagination,
      useRowSelect,
      useIndeterminateRowSelectCheckbox
    );

    const { open: isOpen, onOpen, onClose } = useModal();
    const handleBulkDelete = useCallback(async () => {
      await dispatch(
        deleteNotificationTemplates(
          table.selectedFlatRows.map((row) => row.original.id)
        )
      ).unwrap();

      alert.success(
        <TransAlert
          i18nKey="templatesRemoved"
          values={{ count: table.selectedFlatRows.length }}
        />
      );
      onClose();
      dispatch(getNotificationTemplates(notificationTemplatesFilter));
    }, [
      dispatch,
      table.selectedFlatRows,
      alert,
      onClose,
      notificationTemplatesFilter,
    ]);

    return (
      <Loadable loading={loading}>
        <SearchResult results={data.length} loading={loading}>
          <Table
            data-testid={dtid.templatesSearchResults}
            caption={
              <TableCaption>
                <Button
                  disabled={_isEmpty(table.selectedFlatRows)}
                  onClick={onOpen}
                  startIcon={<Icon name="trash" />}
                  sx={{ ml: 'auto', px: 1, py: 0 }}
                  data-testid={dtid.templatesDeleteSelected}
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <ConfirmDeleteModal
                  handleDelete={handleBulkDelete}
                  title={<TransModal i18nKey="deleteNotificationTemplate" />}
                  description={
                    <TransModal
                      i18nKey="deleteNotificationTemplateDescription"
                      values={{ count: table.selectedFlatRows.length }}
                    />
                  }
                  isOpen={isOpen}
                  onClose={onClose}
                />
              </TableCaption>
            }
            table={table}
            getRowProps={(_, { row }) => ({
              sx: { cursor: 'pointer' },
              onClick: () => history.push(link(row)),
            })}
          />
        </SearchResult>
      </Loadable>
    );
  };
