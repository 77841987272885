import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import {
  AddButton,
  Button,
  FormProvider,
  RadioGroupField,
  SearchForm,
  SelectField,
  TextField,
  formSubmit,
  useForm,
  FormControl,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { NotificationTemplateSearchFilter } from 'dto/notificationTemplate';
import {
  getNotificationTemplates,
  setNotificationTemplatesFilter,
} from 'features/notificationTemplate/notificationTemplateActions';
import { notificationTemplatesFilterSelector } from 'features/notificationTemplate/notificationTemplateSelectors';
import { TransButton } from 'i18n/trans/button';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { dtid } from 'helpers/data-test-ids';

interface NotificationsTemplateSearchFormProps {}

export const NotificationTemplatesSearchForm: FC<NotificationsTemplateSearchFormProps> =
  () => {
    const dispatch = useDispatch();
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const businessEntities = useClassificationOptions(
      ClassificationGroup.BUSINESS_ENTITY
    );
    const templateTypes = useClassificationOptions(
      ClassificationGroup.NOTIFICATION_TEMPLATE_TYPE
    );
    const languages = useClassificationOptions(ClassificationGroup.CULTURE);
    const filter = useSelector(notificationTemplatesFilterSelector);

    const onSubmit = (values: NotificationTemplateSearchFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getNotificationTemplates({ ...values, offset: 0 }));
      });

    const initialValues = useMemo<Partial<NotificationTemplateSearchFilter>>(
      () => ({
        organizationId: currentBusinessEntityId,
        ...filter,
      }),
      [filter, currentBusinessEntityId]
    );

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        dispatch(setNotificationTemplatesFilter(initialValues));
      }
    }, [dispatch, filter, initialValues]);

    const { form, handleSubmit } = useForm<NotificationTemplateSearchFilter>({
      initialValues,
      onSubmit,
      subscription: { dirty: true },
    });

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    return (
      <SearchForm
        title={<TransTitle i18nKey="notificationTemplates" />}
        action={
          <AddButton
            component={Link}
            to="/notification-templates/create"
            data-testid={dtid.templatesAdd}
          >
            <TransButton i18nKey="add" />
          </AddButton>
        }
      >
        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={{ alignItems: 'center' }}
              spacing={2}
              columns={4}
            >
              <Grid item xs={1}>
                <TextField name="name" label={<TransField i18nKey="name" />} />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="organizationId"
                  label={<TransField i18nKey="owner" />}
                  options={businessEntities}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="typeId"
                  label={<TransField i18nKey="type" />}
                  showEmptyOption
                  options={templateTypes}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="languageId"
                  label={<TransField i18nKey="languages" />}
                  showEmptyOption
                  options={languages}
                />
              </Grid>
              <Grid item xs="auto">
                <RadioGroupField
                  name="hasEmailContent"
                  label={<TransField i18nKey="containsEmail" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs="auto">
                <RadioGroupField
                  name="hasSmsContent"
                  label={<TransField i18nKey="containsSms" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs="auto">
                <RadioGroupField
                  name="hasPushContent"
                  label={<TransField i18nKey="containsPushNotification" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFields" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button
                      variant="contained"
                      type="submit"
                      icon="search"
                      data-testid={dtid.templatesSearch}
                    >
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
