import { formatDate } from '@fleet/shared';
import {
  NotificationChannelColumn,
  NotificationChannelColumnPayload,
  NotificationTemplate,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';

const formateBlackoutTime = (date: string | null) =>
  !_isEmpty(date) && !_isNil(date) ? formatDate(date, 'HH:mm') : null;

export const formateNotificationTemplatePayload = (
  notificationTemplatePayload: NotificationTemplatePayload
) => ({
  ...notificationTemplatePayload,
  blackoutTimeFrom: formateBlackoutTime(
    notificationTemplatePayload.blackoutTimeFrom
  ),
  blackoutTimeTo: formateBlackoutTime(
    notificationTemplatePayload.blackoutTimeTo
  ),
});

export const mapNotificationTableToPayload = (
  notificationTableData: NotificationChannelColumn[]
): NotificationChannelColumnPayload[] => {
  return notificationTableData.map((d) => ({
    orderNumber: d.orderNumber,
    isActive: d.isActive,
    channelId: d.channel.id,
    sendEvenWhenHigherPriorityExists: d.sendEvenWhenHigherPriorityExists,
  }));
};

export const mapNotificationTemplateResponseToPayload = (
  notificationTemplate: NotificationTemplate,
  ownerId: number,
  businessEntities: { value: string | number; label: string }[]
): NotificationTemplatePayload => {
  return {
    ..._pick(notificationTemplate, ['id', 'name', 'isActive', 'validity']),
    fallbackLanguageId: notificationTemplate.fallbackLanguage.id,
    typeId: notificationTemplate.type?.id,
    preferredCustomerLevelId: notificationTemplate.preferredCustomerLevel?.id,
    organizationId: ownerId,
    notificationChannels:
      notificationTemplate.notificationChannels?.map(
        (channel: NotificationChannelColumn) => ({
          orderNumber: channel.orderNumber,
          isActive: channel.isActive,
          channelId: channel.channel?.id,
          sendEvenWhenHigherPriorityExists:
            channel.sendEvenWhenHigherPriorityExists,
        })
      ) || [],
    notificationsByLanguage:
      notificationTemplate.notificationsByLanguage?.map((lang) => ({
        ...lang,
        languageId: lang.language?.id,
        smsSenderName:
          lang.smsSenderName ||
          businessEntities.find((be) => be.value === ownerId)?.label,
      })) || [],
    blackoutTimeFrom:
      notificationTemplate.blackoutTimeFrom &&
      `2001-01-01T${notificationTemplate.blackoutTimeFrom}:00`,
    blackoutTimeTo:
      notificationTemplate.blackoutTimeTo &&
      `2001-01-01T${notificationTemplate.blackoutTimeTo}:00`,
  };
};
