import { useMemo } from 'react';
import { mapNotificationTemplateResponseToPayload } from 'helpers/notificationTemplate/mapper';
import {
  NotificationTemplate,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';

type UseNotificationTemplatePayloadReturn = {
  notificationTemplatePayload: NotificationTemplatePayload | undefined;
  initialValues: NotificationTemplatePayload | { organizationId: number };
};

export const useNotificationTemplatePayload = (
  notificationTemplate: NotificationTemplate | undefined,
  ownerId: number,
  businessEntities: { value: string | number; label: string }[]
): UseNotificationTemplatePayloadReturn => {
  const notificationTemplatePayload = useMemo(() => {
    if (notificationTemplate) {
      return mapNotificationTemplateResponseToPayload(
        notificationTemplate,
        ownerId,
        businessEntities
      );
    }
  }, [notificationTemplate, ownerId, businessEntities]);

  const initialValues:
    | NotificationTemplatePayload
    | { organizationId: number } = useMemo(() => {
    return notificationTemplatePayload ?? { organizationId: ownerId };
  }, [notificationTemplatePayload, ownerId]);

  return {
    notificationTemplatePayload,
    initialValues,
  };
};
